.background {
  background-image: url('/Space.jpg');
  background-size: 30%;
  background-repeat: repeat;
  min-height: 100vh;
}

.content-wrapper {
  background-color: rgba(0, 0, 0, 0.6);
  min-height: 100vh;
}